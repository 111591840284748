<template>
	<GridForm
		:id="id"
		:form-id="data.formId"
		:styles="computedStyles"
		:settings="data.settings"
		:is-user-site="$options.IS_USER_SITE"
		:submit-button-data="data.submitButtonData"
		:theme="data.settings.theme"
	/>
</template>

<script>
import { mapActions } from 'vuex';

import GridForm from '@user/components/grid-components/form/GridForm.vue';
import { useGridForm } from '@user/components/grid-components/form/useGridForm';

const IS_USER_SITE = false;

export default {
	name: 'GridFormProviderBuilder',
	IS_USER_SITE,
	components: { GridForm },
	props: {
		id: {
			type: String,
			required: true,
		},
		data: {
			type: Object,
			required: true,
		},
	},
	setup(props) {
		const { computedStyles } = useGridForm(props);

		return { computedStyles };
	},
	mounted() {
		this.generateFormToken();
	},
	methods: mapActions('forms', ['generateFormToken']),
};
</script>
